import type { ReactElement } from "react";

export default function Logo(): ReactElement {
  return (
    <svg width="123" height="43" viewBox="0 0 123 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M100.522 5.70091L91.027 32.801H96.9159L98.7998 27.2644H108.617L110.299 32.801H116.349L106.974 5.70091H100.522ZM100.242 22.3174L103.648 11.693L103.687 12.0141L107.013 22.6388L100.242 22.3174Z"
        fill="#00C3C9"
      />
      <path d="M123 5.70091H117.198V32.801H123V5.70091Z" fill="#00C3C9" />
      <path
        d="M11.0828 12.4171C8.43855 12.4171 6.09198 13.7445 5.22169 15.5173V5.70097H0V32.8011H5.22169V21.3934C5.22169 18.7355 6.72873 16.5629 9.33378 16.5629C11.6975 16.5629 12.7641 17.4872 12.7641 20.4662V32.8011H17.9858V18.7355C17.9858 14.7935 15.1299 12.4171 11.0828 12.4171Z"
        fill="#184B66"
      />
      <path
        d="M54.1481 21.961C52.7014 21.3884 50.6858 20.9682 48.5539 20.3583C46.5369 19.7825 45.51 19.325 45.51 18.2168C45.51 17.1089 46.5758 16.633 48.4393 16.633C50.6858 16.633 51.6742 17.3566 51.9405 19.105H56.6966C56.43 15.0254 53.9949 12.9264 48.5928 12.9264C42.8843 12.9264 40.7153 15.3427 40.7153 18.5143C40.7153 20.1951 41.3242 21.6521 43.0746 22.76C44.4067 23.6004 46.1575 24.1377 49.3148 24.9029C50.9136 25.2858 52.3217 25.7075 52.3217 27.0456C52.3217 28.4586 50.7618 28.9406 49.0108 28.9406C46.6129 28.9406 45.1288 27.8472 45.1288 26.0988H40.0304C40.1064 29.887 43.3024 32.6883 49.0482 32.6883C54.3758 32.6883 57.2295 30.3842 57.2295 26.4844C57.2295 24.0381 56.1265 22.7641 54.1481 21.961Z"
        fill="#184B66"
      />
      <path
        d="M63.2405 7.4493H58.3089V25.2963C58.3089 29.5633 60.5569 32.7422 66.3265 32.7422C71.8958 32.7422 74.2641 29.5207 74.2641 25.3357V19.6881H69.3325V24.854C69.3325 27.3084 68.3488 28.7179 66.2662 28.7179C64.1421 28.7179 63.2405 27.2694 63.2405 24.7721V16.7741H74.2641V12.9859H63.2405V7.4493Z"
        fill="#184B66"
      />
      <path d="M80.3561 5.70091H75.1344V10.6547H80.3561V5.70091Z" fill="#184B66" />
      <path d="M80.3561 12.9859H75.1344V32.801H80.3561V12.9859Z" fill="#184B66" />
      <path
        d="M89.463 12.9859C89.463 10.6547 89.9292 9.92328 92.8301 9.92328V5.69596C87.0282 5.69596 84.3341 8.0324 84.3341 12.9862H81.5165V16.7744H84.4174V32.8013H89.349V16.7744H92.8301V12.9862L89.463 12.9859Z"
        fill="#184B66"
      />
      <path
        d="M29.2995 13.5506V13.1392C30.4599 12.9119 31.4935 11.7789 31.4935 10.423C31.4935 8.89785 30.2238 7.65853 28.7057 7.65853C27.1873 7.65853 25.9161 8.89785 25.9161 10.423C25.9161 11.7786 26.9788 12.9119 28.1391 13.1392V13.5844C22.9174 13.9332 19.4363 17.5011 19.1462 22.8416V32.801H38.8726V22.8416C38.5825 17.2112 34.8113 13.5943 29.2995 13.5506ZM35.1043 21.6944C34.9018 21.7328 34.7379 21.7474 34.5499 21.6894C34.2607 21.6165 34.0582 21.428 33.9283 21.1471C33.5665 20.1109 32.8822 19.2976 31.875 18.7069C31.4459 18.4552 31.2869 17.9079 31.5425 17.4769C31.7929 17.0509 32.3713 16.8912 32.7954 17.143C34.1403 17.9417 35.1139 19.0604 35.6151 20.5078C35.7889 20.9583 35.5528 21.5201 35.1043 21.6944Z"
        fill="#184B66"
      />
      <path
        d="M28.7193 0C28.0924 0 27.5589 0.515194 27.5589 1.14491V4.85616C27.5589 5.48588 28.0924 6.00136 28.7193 6.00136C29.3462 6.00136 29.8797 5.48588 29.8797 4.85616V1.14491C29.8797 0.515194 29.3462 0 28.7193 0Z"
        fill="#00C3C9"
      />
      <path
        d="M37.2742 4.59769C36.9238 4.07551 36.2116 3.93621 35.6917 4.28822L32.6278 6.36241C32.1079 6.71442 31.969 7.4298 32.3194 7.95199C32.6698 8.47418 33.382 8.61346 33.9019 8.26145L36.9658 6.18728C37.4857 5.83556 37.6243 5.12017 37.2742 4.59769Z"
        fill="#00C3C9"
      />
      <path
        d="M24.7102 6.3627L21.6462 4.28852C21.1264 3.93651 20.4145 4.0758 20.0641 4.59798C19.7136 5.12017 19.8523 5.83585 20.3721 6.18757L23.4361 8.26204C23.956 8.61405 24.6682 8.47476 25.0186 7.95257C25.3687 7.4298 25.2301 6.71442 24.7102 6.3627Z"
        fill="#00C3C9"
      />
      <path
        d="M59.1792 37.7548H60.0495V36.8806H59.1792V35.4236H60.0495V34.5494H58.3089V40.6688H60.0495V39.7946H59.1792V37.7548Z"
        fill="#184B66"
      />
      <path
        d="M62.2548 36.1038C61.9983 36.1038 61.7796 36.2978 61.6328 36.5892H61.596L61.5777 36.0064H60.6297V40.6688H61.4999V37.0488C61.79 36.9086 61.6981 36.8436 61.8083 36.8436C61.9275 36.8436 62.0804 36.9275 62.0804 37.077V40.6688H62.9507V36.8899C62.9504 36.3666 62.658 36.1038 62.2548 36.1038Z"
        fill="#184B66"
      />
      <path
        d="M65.1632 35.9907C64.8792 35.9907 64.677 36.2978 64.5668 36.2978H64.4009V34.5494H63.5306V40.6688H64.4009V37.1239C64.691 36.9366 64.581 36.8436 64.7092 36.8436C64.8282 36.8436 64.9811 36.9185 64.9811 37.077V40.6688H65.8514V36.9366C65.8514 36.4505 65.6674 35.9907 65.1632 35.9907Z"
        fill="#184B66"
      />
      <path
        d="M67.6334 36.058C66.9456 36.058 66.4315 36.3386 66.4315 36.9372V37.4634H67.3018V37.0488C67.3018 36.9086 67.464 36.834 67.6009 36.834C67.7294 36.834 67.882 36.8993 67.882 37.0214V37.6289C67.882 37.8347 67.661 37.8723 67.3401 37.9559C66.7445 38.115 66.4315 38.3391 66.4315 38.9376V39.9846C66.4315 40.4147 66.6088 40.7513 67.1223 40.7513C67.5443 40.7513 67.703 40.3777 67.786 40.3777H67.8225L67.8408 40.6691H68.7523V36.8812C68.7523 36.32 68.3021 36.058 67.6334 36.058ZM67.882 39.7978C67.882 39.9001 67.7199 39.9563 67.5919 39.9563C67.4547 39.9563 67.3018 39.8817 67.3018 39.7419V38.7689C67.3018 38.6197 67.4361 38.5168 67.5733 38.4795C67.6656 38.4513 67.5919 38.4329 67.882 38.3956V39.7978Z"
        fill="#184B66"
      />
      <path
        d="M70.9208 36.1038C70.664 36.1038 70.4084 36.2978 70.2617 36.5892H70.2248L70.2068 36.0064H69.3325V40.6688H70.2028V37.0488C70.2028 36.9086 70.4012 36.8436 70.5114 36.8436C70.6301 36.8436 70.783 36.9275 70.783 37.077V40.6688H71.6532V36.8899C71.6532 36.3666 71.324 36.1038 70.9208 36.1038Z"
        fill="#184B66"
      />
      <path
        d="M73.2624 36.0583C72.5287 36.0583 72.2334 36.4135 72.2334 37.1516V39.6477C72.2334 40.3862 72.5287 40.7414 73.2534 40.7414C73.9865 40.7414 74.2641 40.3771 74.2641 39.6477V38.9204H73.6839V39.6477C73.6839 39.8351 73.5269 39.9284 73.3894 39.9284C73.2615 39.9284 73.1037 39.8351 73.1037 39.6477V37.1516C73.1037 36.9648 73.2615 36.8713 73.399 36.8713C73.5365 36.8713 73.6839 36.9648 73.6839 37.1516V37.7548H74.2641V37.1516C74.2641 36.4132 73.9865 36.0583 73.2624 36.0583Z"
        fill="#184B66"
      />
      <path
        d="M76.1451 36.058C75.4114 36.058 75.1344 36.4135 75.1344 37.1516V39.6475C75.1344 40.3864 75.4114 40.7414 76.1358 40.7414C76.8691 40.7414 77.165 40.3771 77.165 39.6475V39.2118H76.2947V39.667C76.2947 39.8538 76.2828 39.9473 76.1453 39.9473C76.0171 39.9473 76.0046 39.8538 76.0046 39.667V38.629H77.165V37.1516C77.165 36.4132 76.8691 36.058 76.1451 36.058ZM76.2947 38.0462H76.0046V37.1333C76.0046 36.9366 76.0171 36.8526 76.1549 36.8526C76.2924 36.8526 76.2947 36.9366 76.2947 37.1333V38.0462Z"
        fill="#184B66"
      />
      <path
        d="M80.6461 39.5921C80.3561 39.7879 80.4709 39.8724 80.3508 39.8724C80.2235 39.8724 80.066 39.7879 80.066 39.5921V36.0064H79.1957V39.7978C79.1957 40.3395 79.35 40.7046 79.8725 40.7046C80.1202 40.7046 80.3024 40.6688 80.4402 40.3774H80.3561V41.7796C80.3561 41.9664 80.3532 42.0602 80.2157 42.0602C80.0781 42.0602 80.066 41.9664 80.066 41.7796V40.9602H79.1957V41.7796C79.1957 42.518 79.4733 42.8732 80.1977 42.8732C80.9307 42.8732 81.2263 42.518 81.2263 41.7796V36.0064H80.6461V39.5921Z"
        fill="#184B66"
      />
      <path
        d="M83.1122 36.0583C82.3786 36.0583 82.0966 36.4042 82.0966 37.1516V39.6477C82.0966 40.3955 82.3786 40.7414 83.1122 40.7414C83.8363 40.7414 84.1273 40.3955 84.1273 39.6477V37.1516C84.1273 36.4039 83.8363 36.0583 83.1122 36.0583ZM83.257 39.6477C83.257 39.8351 83.2448 39.9284 83.1172 39.9284C82.9887 39.9284 82.9669 39.8351 82.9669 39.6477V37.1516C82.9669 36.9648 82.9884 36.8713 83.1172 36.8713C83.2448 36.8713 83.257 36.9648 83.257 37.1516V39.6477Z"
        fill="#184B66"
      />
      <path
        d="M86.1579 39.6294C86.1579 39.8724 86.1687 39.9563 86.0129 39.9563C85.8475 39.9563 85.8678 39.8724 85.8678 39.6294V36.0064H84.9976V39.6294C84.9976 40.2652 85.0434 40.779 85.639 40.779C85.923 40.779 86.0518 40.6688 86.2168 40.3774H86.2534L86.2627 40.6688H87.0282V36.0064H86.1579V39.6294Z"
        fill="#184B66"
      />
      <path
        d="M89.2042 36.1038C88.9109 36.1038 88.803 36.2978 88.7021 36.5892H88.6655L88.6472 36.0064H87.8985V40.6688H88.7688V37.4419C88.7688 37.068 88.8161 36.8713 89.2007 36.8713C89.302 36.8713 89.349 36.8812 89.349 36.9086V36.0953C89.349 36.0676 89.2779 36.1038 89.2042 36.1038Z"
        fill="#184B66"
      />
      <path
        d="M92.2451 36.058C91.481 36.058 91.0896 36.4135 91.0896 37.1516V39.6475C91.0896 40.3864 91.4813 40.7414 92.2355 40.7414C92.9996 40.7414 93.4104 40.3771 93.4104 39.6475V39.2118H92.25V39.667C92.25 39.8538 92.2439 39.9473 92.1003 39.9473C91.9672 39.9473 91.9599 39.8538 91.9599 39.667V38.629H93.4104V37.1516C93.4104 36.4132 92.9996 36.058 92.2451 36.058ZM92.25 38.0462H91.9599V37.1333C91.9599 36.9366 91.9672 36.8526 92.1102 36.8526C92.2535 36.8526 92.25 36.9366 92.25 37.1333V38.0462Z"
        fill="#00C3C9"
      />
      <path
        d="M96.2008 36.0064H95.2841L95.0169 37.4634H94.9789L94.6929 36.0064H93.7475L94.3871 38.3003L93.719 40.6688H94.6639L94.9409 39.2118H94.9789L95.2655 40.6688H96.2292L95.5704 38.2068L96.2008 36.0064Z"
        fill="#00C3C9"
      />
      <path
        d="M98.4043 36.104C98.1082 36.104 97.8627 36.2978 97.7099 36.5892H97.6716L97.6623 36.0064H96.8915V43H97.7618V40.3774H97.7769C97.9013 40.6688 98.1656 40.7274 98.4136 40.7274C98.8525 40.7274 99.2123 40.4144 99.2123 39.9097V36.8806C99.2123 36.3759 98.8627 36.104 98.4043 36.104ZM98.0519 39.676C98.0519 39.8628 98.0452 39.9563 97.9022 39.9563C97.778 39.9563 97.7618 39.8817 97.7618 39.7416V37.049C97.7618 36.9089 97.7783 36.8439 97.9022 36.8439C98.0449 36.8439 98.0519 36.9369 98.0519 37.1242V39.676Z"
        fill="#00C3C9"
      />
      <path
        d="M100.948 36.058C100.184 36.058 99.7925 36.4135 99.7925 37.1516V39.6475C99.7925 40.3864 100.184 40.7414 100.938 40.7414C101.702 40.7414 102.113 40.3771 102.113 39.6475V39.2118H101.243V39.667C101.243 39.8538 101.091 39.9473 100.948 39.9473C100.815 39.9473 100.663 39.8538 100.663 39.667V38.629H102.113V37.1516C102.113 36.4132 101.702 36.058 100.948 36.058ZM101.243 38.0462H100.663V37.1333C100.663 36.9366 100.815 36.8526 100.958 36.8526C101.101 36.8526 101.243 36.9366 101.243 37.1333V38.0462Z"
        fill="#00C3C9"
      />
      <path
        d="M103.726 36.5892H103.687L103.668 36.0064H102.693V40.6688H103.854V37.4419C103.854 37.068 103.891 36.8713 104.292 36.8713C104.397 36.8713 104.434 36.8812 104.434 36.9086V36.0953C104.434 36.0676 104.343 36.1038 104.266 36.1038C103.96 36.1038 103.83 36.2978 103.726 36.5892Z"
        fill="#00C3C9"
      />
      <path d="M106.175 34.8408H105.014V35.715H106.175V34.8408Z" fill="#00C3C9" />
      <path d="M106.175 36.0064H105.014V40.6688H106.175V36.0064Z" fill="#00C3C9" />
      <path
        d="M107.91 36.058C107.146 36.058 106.755 36.4135 106.755 37.1516V39.6475C106.755 40.3864 107.146 40.7414 107.9 40.7414C108.664 40.7414 109.075 40.3771 109.075 39.6475V39.2118H108.205V39.667C108.205 39.8538 108.054 39.9473 107.91 39.9473C107.777 39.9473 107.625 39.8538 107.625 39.667V38.629H109.075V37.1516C109.075 36.4132 108.664 36.058 107.91 36.058ZM108.205 38.0462H107.625V37.1333C107.625 36.9366 107.777 36.8526 107.92 36.8526C108.064 36.8526 108.205 36.9366 108.205 37.1333V38.0462Z"
        fill="#00C3C9"
      />
      <path
        d="M111.317 36.1038C111.05 36.1038 110.887 36.2978 110.733 36.5892H110.695L110.676 36.0064H109.946V40.6688H110.816V37.0488C110.816 36.9086 110.866 36.8436 110.98 36.8436C111.104 36.8436 111.106 36.9275 111.106 37.077V40.6688H111.976V36.8899C111.976 36.3666 111.737 36.1038 111.317 36.1038Z"
        fill="#00C3C9"
      />
      <path
        d="M114.021 36.0583C113.257 36.0583 112.847 36.4135 112.847 37.1516V39.6477C112.847 40.3862 113.257 40.7414 114.012 40.7414C114.776 40.7414 115.167 40.3771 115.167 39.6477V38.9204H114.007V39.6477C114.007 39.8351 114.001 39.9284 113.857 39.9284C113.724 39.9284 113.717 39.8351 113.717 39.6477V37.1516C113.717 36.9648 113.724 36.8713 113.867 36.8713C114.011 36.8713 114.007 36.9648 114.007 37.1516V37.7548H115.167V37.1516C115.167 36.4132 114.775 36.0583 114.021 36.0583Z"
        fill="#00C3C9"
      />
      <path
        d="M116.903 36.058C116.139 36.058 115.748 36.4135 115.748 37.1516V39.6475C115.748 40.3864 116.139 40.7414 116.893 40.7414C117.658 40.7414 118.068 40.3771 118.068 39.6475V39.2118H117.198V39.667C117.198 39.8538 117.046 39.9473 116.903 39.9473C116.77 39.9473 116.618 39.8538 116.618 39.667V38.629H118.068V37.1516C118.068 36.4132 117.658 36.058 116.903 36.058ZM117.198 38.0462H116.618V37.1333C116.618 36.9366 116.77 36.8526 116.913 36.8526C117.057 36.8526 117.198 36.9366 117.198 37.1333V38.0462Z"
        fill="#00C3C9"
      />
      <path
        d="M120.626 38.3391C120.387 38.1424 120.025 38.0214 119.785 37.853C119.595 37.7129 119.519 37.6566 119.519 37.5068V36.9742C119.519 36.8529 119.69 36.7967 119.813 36.7967C119.947 36.7967 120.099 36.8626 120.099 36.9934V37.4634H120.969V37.0394C120.969 36.4231 120.505 36.058 119.79 36.058C119.112 36.058 118.648 36.3759 118.648 36.8899V37.5065C118.648 37.7778 118.749 38.0214 118.949 38.2359C119.121 38.423 119.557 38.6474 119.767 38.7972C120.006 38.9656 120.099 39.0591 120.099 39.2086V39.8252C120.099 39.9374 119.971 40.0027 119.818 40.0027C119.665 40.0027 119.519 39.9371 119.519 39.7975V39.2118H118.648V39.7696C118.648 40.4054 119.036 40.7414 119.838 40.7414C120.592 40.7414 120.969 40.4334 120.969 39.8068V39.1806C120.969 38.7132 120.836 38.5075 120.626 38.3391Z"
        fill="#00C3C9"
      />
      <path
        d="M121.84 34.5494V37.1706C121.84 37.8344 121.8 38.3376 121.896 39.2118H122.545C122.659 38.3376 122.71 37.6286 122.71 36.834V34.5494H121.84Z"
        fill="#00C3C9"
      />
      <path d="M122.71 39.7946H121.84V40.6688H122.71V39.7946Z" fill="#00C3C9" />
    </svg>
  );
}
