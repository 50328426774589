import { StyledTypography } from "./StyledTypography.style";
import type { IHeading } from "./types";

import type { FC } from "react";

export const Body2: FC<IHeading> = ({ value, weight, ...props }) => {
  return (
    <StyledTypography variant="body2" weight={weight} {...props}>
      {value}
    </StyledTypography>
  );
};
